.document-container {
  background: rgb(204,204,204);
  padding:1rem;
}

.document-container.full {
  padding:0
}

.document-container.flexwrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen {
  .document-container {
    min-height: calc(100vh - 128px);
  }
}

.document {
  display: block;
  background: white;
  margin: 0 auto;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
  padding: 0.7cm 1cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
.document-page{
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.document-content{
  flex-grow: 1;
}
.document-header,
.document-content,
.document-footer{
  flex-shrink: 0;
}

.document.A4 {
  width: 21cm;
}
.document.A4landscape {
  width: 29.7cm;
}
.document.A5 {
  width: 14.85cm;
}
.document.A5landscape {
  width: 21cm;
}
.document.A3landscape {
  width: 42cm;
}
.document.A2landscape {
  width: 59.4cm;
}
.document.half{
  width: 50%;
}
.document.full {
  width: 100%;
}
.document.fullA4 {
  width: 100%;
  max-width: 21cm;
}
.document.barcode58 {
  width: 5.8cm;
}

.document-page.A4,
.document-page.fullA4{
  min-height: calc(29.7cm - 2cm);
}
.document-page.A4landscape{
  min-height: calc(21cm - 2.42cm);
}
.document-page.A5{
  min-height: calc(21cm - 2cm);
}
.document-page.A5landscape{
  min-height: calc(14.85cm - 2cm);
}
.document-page.A3landscape{
  min-height: calc(29.7cm - 2cm);
}
.document-page.A2landscape{
  min-height: calc(42cm - 2cm);
}
.document-page.barcode58{
  min-height: calc(2.8cm - 2cm);
}

/*
.document.A3 {
  width: 29.7cm;
  height: 42cm;
}
.document.A3.landscape {
  width: 42cm;
  height: 29.7cm;
}
.document.A5 {
  width: 14.8cm;
  height: 21cm;
}
*/

@media screen and (max-width: 979px) {
  .document-container {
    padding:0
  }
  .document {
    padding: 0.1cm 0.12cm;
    margin-top: 0cm;
    margin-bottom: 0.1cm;
  }
  .document.A4 {
    width: 100%;
  }
  .document.A4landscape {
    width: 100%;
  }
  .document.A5 {
    width: 100%;
  }
  .document.A5landscape {
    width: 100%;
  }
  .document.half {
    width: 100%;
  }
}

@media print {
  body{
    margin: 0;
  }
  .document-container {
    padding: 0;
    background-color: white;
  }
  .document {
    page-break-before: always;
    margin: 0;
    padding: 0.7cm 1cm;
    box-shadow: none;
  }
  .print-container{
    margin-left:5mm;
    margin-right:5mm;
    padding:0;
  }
  .print-document {
    page-break-before: always;
  }
}
