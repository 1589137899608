.report_table{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  /*table-layout: fixed;*/
}

.report_table tr.column-header{
  border: 1px solid #d0d0d0;
}

.report_table tr.column-header th{
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  background-color: #F2F2F2;
  border: 1px solid #d0d0d0;
  -webkit-print-color-adjust: exact;
  padding: 3px 1px;
}

.report_table td{
  font-size: 10px;
  padding: 3px;
  line-height: 13px;
  height:20px;
  border-bottom: 1px solid #F2F2F2;
  border-left: 1px solid #F2F2F2;
  border-right: 1px solid #F2F2F2;
  vertical-align: top;
  -webkit-print-color-adjust: exact;
}

.report_table td > div{
  overflow:hidden;
  height:14px;
}

.report_table td.line2{
  height:40px;
}

.report_table td.line2 > div{
  height:28px;
}
